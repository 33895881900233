/** @jsx jsx */
import Helmet from 'react-helmet'
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import Button from '../components/Button'

function NotFoundPage() {
  return (
    <Layout>
      <Helmet>
        <title>页面未找到 - FDA助手</title>
      </Helmet>
      <div
        sx={{
          paddingY: 9,
          paddingX: 5,
        }}
      >
        <h1
          sx={{
            margin: 0,
            fontSize: 6,
            fontWeight: 'normal',
            textAlign: 'center',
          }}
        >
          页面未找到
        </h1>
        <Button
          as="a"
          href="/"
          sx={{ display: 'block', width: '50%', variant: 'buttons.outline', marginTop: 10, marginX: 'auto' }}
        >
          返回首页
        </Button>
      </div>
    </Layout>
  )
}

export default NotFoundPage
